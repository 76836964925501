import { buildApiRequest } from "./transport";
import { getMessage } from "./utils";

function logIn(username: string, password: string) {
  const requestData = {
    username,
    password,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await buildApiRequest({
        method: "POST",
        target: "/user/login",
        data: requestData,
      });
      resolve(res);
    } catch (error) {
      console.log("API ERROR ->", getMessage(error));
      reject(error);
    }
  });
}

export default {
  logIn,
};
