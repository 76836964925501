import React, { Component } from "react";
import { Form, Button, Container, Spinner } from "react-bootstrap";
import * as R from 'ramda';
import Application from "../../modules/application";

import appState from "../../store/store";

import Styles from "./styles";

interface IProps {
  history: any;
}

interface IState {
  formInputs: any;
  authError: boolean;
  authProcess: boolean;
}

class LoginScreen extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      formInputs: {
        email: "",
        password: "",
      },
      authError: false,
      authProcess: false,
    };
  }

  //------------------------------------------------
  // при изменнении ввода
  //------------------------------------------------
  onChangeInput = (type: string, text: string) => {
    this.setState(({ formInputs }) => ({
      formInputs: {
        ...formInputs,
        [type]: text,
      },
    }));
  };

  //------------------------------------------------
  // авторизация
  //------------------------------------------------
  onPressLogIn = async () => {
    const { formInputs } = this.state;
    const { history } = this.props;

    console.log("HIS", this.props);
    try {
      this.setState({ authError: false, authProcess: true });
      const response = await Application.user.logIn(formInputs.email, formInputs.password);
      const result = R.contains(R.prop('success', response), [true]);
      if(result){
        appState.sessionParams.isAuthenticated = true;
        history.replace({ pathname: "/" });
      }else{
        appState.sessionParams.isAuthenticated = false;
      }
    } catch (error) {
      this.setState({ authError: true, authProcess: false });
      appState.sessionParams.isAuthenticated = false;
    }
  };

  render() {
    const { formInputs, authError, authProcess } = this.state;
    return (
      <Container fluid style={Styles.container}>
        <Form style={Styles.formContainer}>
          <Form.Group>
            <Form.Label>Логин</Form.Label>
            <Form.Control
              type="text"
              disabled={authProcess}
              value={formInputs.email}
              onChange={(event) =>
                this.onChangeInput("email", event.target.value)
              }
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Пароль</Form.Label>
            <Form.Control
              type="password"
              disabled={authProcess}
              value={formInputs.password}
              onChange={(event) =>
                this.onChangeInput("password", event.target.value)
              }
            />
          </Form.Group>

          <Form.Group style={{ visibility: authError ? "visible" : "hidden" }}>
            <Form.Text style={Styles.errorText} className="text-error">
              Неправильный логин или пароль
            </Form.Text>
          </Form.Group>
          <div style={Styles.actionContainer}>
            <Button
              variant="primary"
              disabled={authProcess}
              onClick={this.onPressLogIn}
            >
              {authProcess && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: 12, marginBottom: 2 }}
                />
              )}
              Войти
            </Button>
          </div>
        </Form>
      </Container>
    );
  }
}

export default LoginScreen;
