import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import {
  Modal,
  Button,
  FormControl,
  Form,
  InputGroup,
  Tab,
  Nav,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

import {
  Table,
  Popover,
  Position,
  Menu,
  Avatar,
  Text,
  IconButton,
  Stack,
  MoreIcon,
  TrashIcon,
} from "evergreen-ui";

import * as R from "ramda";
import classNames from "classnames";

import NewRemainsForm from "../NewRemainsForm";

import Application from "../../../modules/application";
import { generateRandomQR } from "../../../modules/utils";

// import Styles from "./styles";

const FORM_STATE = {
  NEW: 0,
  OBJECT: 1,
  LOADING: 2,
  LOADING_ERROR: 3,
};

interface IProps {
  foodStationData?: any;
  onHide?: any;
  onSave?: any;
  show?: boolean;
  foodStations?: any;
  stationRemains?: any;
}

interface IState {
  formInputs: any;
  formErrors: any;
  fetchingData: boolean;
  fetchingDataError: boolean;
  formState: number;
  newRemainsFormVisible: boolean;
  deletingRemainRecordId: number;
}

@inject("stationRemains", "foodStations")
@observer
class FoodStationFormScreen extends Component<IProps, IState> {
  fieldsValidationConfig = {
    title: {
      required: true,
    },
    qrCode: {
      required: true,
    },
    address: {
      required: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      formInputs: {},
      formErrors: {},
      fetchingData: false,
      fetchingDataError: false,
      newRemainsFormVisible: false,
      formState: FORM_STATE.NEW,
      deletingRemainRecordId: -1,
    };
  }

  componentDidUpdate(prevProps) {
    const { show, foodStations } = this.props;
    // сбросить данные
    if (show !== prevProps.show) {
      if (show && foodStations.currentRowId > 0) {
        this.setState({
          formInputs: {},
          formErrors: {},
          fetchingData: true,
          formState: FORM_STATE.OBJECT,
        });
        this.requestStationData();
        this.requestStationRemainsData();
      } else {
        this.setState({ formInputs: {}, formErrors: {}, fetchingData: false });
      }
    }
  }

  //--------------------------------------------
  // генерировать QR код
  //--------------------------------------------
  generateQRCode = () => {
    const qrCode = generateRandomQR();
    this.setState(({ formInputs }) => ({
      formInputs: {
        ...formInputs,
        qrCode: qrCode,
      },
    }));
  };

  //--------------------------------------------
  // показать форму добавления остатков
  //--------------------------------------------
  showHideRemainsFrom = (data) => {
    const { stationRemains } = this.props;

    // получить идентификатор товара
    const selectedGoodId = R.pathOr(-1, ["goodId"], data);

    // запомнить
    stationRemains.currentGoodId = selectedGoodId;

    this.setState((prevState) => ({
      newRemainsFormVisible: !prevState.newRemainsFormVisible,
    }));
  };

  //--------------------------------------------
  // получить данные
  //--------------------------------------------
  requestStationData = async () => {
    const { foodStations } = this.props;
    try {
      const response: any = await Application.stations.requestById(
        foodStations.currentRowId
      );
      const result = R.contains(R.prop("success", response), [true]);
      if (result) {
        console.log("STATION", response);
        this.setState({ formInputs: response.data, fetchingData: false });
      }
    } catch (error) {
      this.setState({ fetchingDataError: true });
    }
  };

  //--------------------------------------------
  // получить остатки
  //--------------------------------------------
  requestStationRemainsData = async () => {
    const { foodStations, stationRemains } = this.props;
    try {
      const response: any = await Application.stations.requestRemains(
        foodStations.currentRowId,
        null
      );
      const result = R.contains(R.prop("success", response), [true]);
      if (result) {
        console.log("STATION REMAINS", response);
        stationRemains.data = response.data;
      }
    } catch (error) {
      this.setState({ fetchingDataError: true });
    }
  };

  //--------------------------------------------
  // валидация данных
  //--------------------------------------------
  validateInputs = () => {
    const { formInputs } = this.state;

    const keys = Object.keys(this.fieldsValidationConfig);
    const errors = {};
    let hasErrors = false;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const valueValid = formInputs[key];
      errors[key] = !valueValid;
      if (!valueValid) hasErrors = true;
    }
    this.setState({ formErrors: errors });

    return !hasErrors;
  };

  //--------------------------------------------
  // добавить станцию еды на сервер
  //--------------------------------------------
  addOnServer = async () => {
    const { formInputs } = this.state;

    return new Promise(async (resolve, reject) => {
      if (this.validateInputs()) {
        this.setState({ fetchingData: true });
        try {
          await Application.stations.addNew(formInputs);
          this.setState({ fetchingData: false }, () => {
            return resolve();
          });
        } catch (error) {
          this.setState({ fetchingData: false }, () => {
            return reject();
          });
        }
      }
    });
  };

  //--------------------------------------------
  // обновить данные на сервере сервер
  //--------------------------------------------
  updateOnServer = () => {
    const { formInputs } = this.state;
    const { foodStations } = this.props;

    return new Promise(async (resolve, reject) => {
      if (this.validateInputs()) {
        this.setState({ fetchingData: true });
        try {
          await Application.stations.update({
            id: foodStations.currentRowId,
            ...formInputs,
          });
          this.setState({ fetchingData: false }, () => {
            return resolve();
          });
        } catch (error) {
          this.setState({ fetchingData: false });
          return reject();
        }
      }
    });
  };

  //------------------------------------------------
  // подтвердить (отправить на сервере) введенные данные в форме
  //------------------------------------------------
  postObject = (close = false) => {
    const { formState } = this.state;
    const { onSave } = this.props;

    if (formState === FORM_STATE.NEW) {
      return this.addOnServer().then(() => {
        if (onSave) {
          if (close) this.setState({ formInputs: {}, formErrors: {} });
          onSave(close);
        }
      });
    }

    return this.updateOnServer().then(() => {
      if (onSave) {
        if (close) this.setState({ formInputs: {}, formErrors: {} });
        onSave(close);
      }
    });
  };

  //------------------------------------------------
  // при изменнении ввода
  //------------------------------------------------
  onChangeInput = (type: string, text: string) => {
    this.setState(({ formInputs }) => ({
      formInputs: {
        ...formInputs,
        [type]: text,
      },
    }));
  };

  //------------------------------------------------
  // всплывающее меню
  //------------------------------------------------
  renderRowMenu = () => {
    return (
      <Menu>
        <Menu.Group>
          <Menu.Item>Изменить...</Menu.Item>
        </Menu.Group>
        <Menu.Divider />
        <Menu.Group>
          <Menu.Item intent="danger">Удалить...</Menu.Item>
        </Menu.Group>
      </Menu>
    );
  };

  //-------------------------------------------------
  // удалить строку остатка из станции
  //-------------------------------------------------
  deleteGoodRemains = (event, element) => {
    event.stopPropagation();
    console.log("DELETE", element);
    try {
      this.setState({ deletingRemainRecordId: element.id }, async () => {
        await Application.stations.deleteRemainsRecord({
          stationId: element.stationId,
          goodId: element.goodId,
        });
        await this.requestStationRemainsData();
        this.setState({ deletingRemainRecordId: -1 });
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  //-------------------------------------------------
  // рисовать форму
  //-------------------------------------------------
  renderFormData = () => {
    const { formInputs, formErrors } = this.state;

    const { foodStations } = this.props;

    const isNew = foodStations.currentRowId < 0;

    return (
      <>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Наименование</Form.Label>
            <FormControl
              type="text"
              className="mr-sm-2"
              isInvalid={formErrors.title || false}
              value={formInputs.title}
              onChange={(event) =>
                this.onChangeInput("title", event.target.value)
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Адрес</Form.Label>
            <FormControl
              type="text"
              className="mr-sm-2"
              isInvalid={formErrors.address || false}
              value={formInputs.address}
              onChange={(event) =>
                this.onChangeInput("address", event.target.value)
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Координаты (ширина)</Form.Label>
            <FormControl
              type="number"
              step="0.000001"
              placeholder="12.123456"
              pattern="2-[0-9]{3}-[0-9]{3}"
              className="mr-sm-2"
              value={formInputs.latitude}
              onChange={(event) =>
                this.onChangeInput("latitude", event.target.value)
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Координаты (долгота)</Form.Label>
            <FormControl
              type="number"
              step="0.000001"
              placeholder="12.123456"
              pattern="2-[0-9]{3}-[0-9]{3}"
              className="mr-sm-2"
              value={formInputs.longitude}
              onChange={(event) =>
                this.onChangeInput("longitude", event.target.value)
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>QR код</Form.Label>
            <InputGroup>
              <FormControl
                type="text"
                className="mr-sm-2"
                isInvalid={formErrors.qrCode || false}
                value={formInputs.qrCode}
                onChange={(event) =>
                  this.onChangeInput("qrCode", event.target.value)
                }
              />
              <InputGroup.Append>
                <Button
                  variant="outline-info"
                  onClick={() => this.generateQRCode()}
                >
                  Генерировать
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            color="primary"
            onClick={() => this.postObject(false)}
          >
            {isNew ? "Добавить" : "Сохранить"}
          </Button>
          <Button onClick={() => this.postObject(true)}>
            {isNew ? "Добавить и закрыть" : "Сохранить и закрыть"}
          </Button>
        </Modal.Footer>
      </>
    );
  };

  //--------------------------------------------------------
  // рисовать таблицу остатков
  //--------------------------------------------------------
  renderStationRemains = () => {
    const { newRemainsFormVisible, deletingRemainRecordId } = this.state;
    const { stationRemains } = this.props;

    let rowsRenderData: any = [];

    rowsRenderData = stationRemains.data.map((element, index) => {
      const goodName = R.pathOr(
        "Не удалось прочитать",
        ["good", "name"],
        element
      );
      const goodBarCode = R.pathOr("-", ["good", "barCode"], element);
      const price = R.pathOr("0.0", ["price"], element);
      const remains = R.pathOr("-", ["quantity"], element);

      return (
        <Table.Row
          key={element.id}
          isSelectable
          onSelect={() => {
            this.showHideRemainsFrom(element);
          }}
        >
          <Table.TextCell
            width={48}
            flex="none"
            textProps={{ textAlign: "center" }}
          >
            {index + 1}
          </Table.TextCell>
          <Table.TextCell textProps={{ size: 400 }}>{goodName}</Table.TextCell>
          <Table.TextCell isNumber textProps={{ textAlign: "center" }}>
            {price}
          </Table.TextCell>
          <Table.TextCell isNumber textProps={{ textAlign: "center" }}>
            {remains}
          </Table.TextCell>
          <Table.TextCell isNumber textProps={{ textAlign: "center" }}>
            {goodBarCode}
          </Table.TextCell>
          <Table.Cell width={48} flex="none">
            {deletingRemainRecordId === element.id ? <Spinner animation="border" role="status" variant="info" size="sm" /> : <IconButton
              color="danger"
              icon={TrashIcon}
              height={40}
              appearance="minimal"
              intent="danger"
              onClick={(event) => this.deleteGoodRemains(event, element)}
            />}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table>
        <Button
          style={{ marginLeft: 8 }}
          variant="link"
          onClick={() => {
            this.showHideRemainsFrom(null);
          }}
        >
          Добавить товар
        </Button>
        <Table.Head>
          <Table.TextHeaderCell
            width={48}
            flex="none"
            textProps={{ textAlign: "center", fontSize: 14 }}
          >
            #
          </Table.TextHeaderCell>
          <Table.TextHeaderCell
            textProps={{ textAlign: "center", fontSize: 14 }}
          >
            Наименование
          </Table.TextHeaderCell>
          <Table.TextHeaderCell
            textProps={{ textAlign: "center", fontSize: 14 }}
          >
            Цена (на станции)
          </Table.TextHeaderCell>
          <Table.TextHeaderCell
            textProps={{ textAlign: "center", fontSize: 14 }}
          >
            Остаток (на станции)
          </Table.TextHeaderCell>
          <Table.TextHeaderCell
            textProps={{ textAlign: "center", fontSize: 14 }}
          >
            Штрих-код
          </Table.TextHeaderCell>
          <Table.HeaderCell width={48} flex="none" />
        </Table.Head>
        {newRemainsFormVisible && (
          <Table.Row
            key={"_newRemainsForFoodStation"}
            className={"asap-foodstation-table-new-remains-row"}
            style={{ borderBottom: 0 }}
            height={300}
          >
            <NewRemainsForm
              show={newRemainsFormVisible}
              onCancel={() => this.showHideRemainsFrom(null)}
              onConfirm={() => {
                this.showHideRemainsFrom(null);
                this.requestStationRemainsData();
              }}
            />
          </Table.Row>
        )}
        <Table.VirtualBody height={500}>{rowsRenderData}</Table.VirtualBody>
      </Table>
    );
  };

  //--------------------------------------------------------
  // рисовать форму
  //--------------------------------------------------------
  renderForm = () => {
    const { foodStations } = this.props;

    const remainsAvailable = foodStations.currentRowId > 0;

    return (
      <Tab.Container id="left-tabs-example" defaultActiveKey="station">
        <Row className={"asap-tab-navigation-container"}>
          <Col sm={2}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="station">Основные</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  disabled={!remainsAvailable}
                  eventKey="stationRemains"
                >
                  Товары
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="station">{this.renderFormData()}</Tab.Pane>
              <Tab.Pane eventKey="stationRemains">
                {this.renderStationRemains()}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  };

  render() {
    const { onHide, show, foodStations } = this.props;

    // создать заголовок
    const title =
      foodStations.currentRowId > 0 ? "Станция еды" : "Станция еды (Новая)";

    return (
      <Modal
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={onHide && onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        {this.renderForm()}
      </Modal>
    );
  }
}

export default FoodStationFormScreen;
