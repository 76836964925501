export default {
  container: {
    // backgroundColor: '#ee43',
    justifyContent: 'center',
    display: 'flex',
  },
  formContainer: {
    marginTop: 150,
    width: 400,
    padding: 20,
    borderRadius: 8,
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
  },
  errorText:{
    color: '#ff3466',
    fontSize: 18,
  },
  actionContainer:{
    display: 'flex',
    justifyContent: 'flex-end',
  }
};
