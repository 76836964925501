import React, { Component } from "react";
import {
  Container,
  Navbar,
  Nav,
  FormControl,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";

import { Table, Text, BarcodeIcon, Pane } from "evergreen-ui";

import { FaTimesCircle } from "react-icons/fa";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import * as R from "ramda";

import GoodForm from "./Form";

import Styles from "./styles";
import Application from "../../modules/application";

interface IProps {
  goods?: any;
}

interface iState {
  formVisible: boolean;
  searchString?: string;
  loadingState?: boolean;
}

@inject("goods")
@observer
class GoodsScreen extends Component<IProps, iState> {
  constructor(props) {
    super(props);
    this.state = {
      formVisible: false,
      searchString: "",
      loadingState: true,
    };
  }

  async componentDidMount() {
    await this.refreshData();
  }

  //------------------------------------------------
  // при изменнении ввода
  //------------------------------------------------
  onChangeInput = (text: string) => {
    this.setState({ searchString: text });
  };

  //------------------------------------------------
  // очистить поиск
  //------------------------------------------------
  clearSearchString = () => {
    this.setState({ searchString: "" });
    this.refreshData();
  };

  //---------------------------------------------
  // обновить данные
  //---------------------------------------------
  refreshData = async () => {
    const { goods } = this.props;
    try {
      this.setState({ loadingState: true });
      const result = await Application.goods.requestList();
      console.log("DATA", result);
      this.setState({ loadingState: false });
      if (result.success) {
        goods.data = result.data;
      }
    } catch (error) {
      this.setState({ loadingState: false });
      console.log("ERROR", error);
    }
  };

  //---------------------------------------------
  //  найти
  //---------------------------------------------
  searchData = async () => {
    const { searchString } = this.state;
    const { goods } = this.props;
    try {
      const result = await Application.goods.search(searchString);
      console.log("DATA", result);
      if (result.success) {
        goods.data = result.data;
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  //---------------------------------------------
  // добавить товар
  //---------------------------------------------
  onPressAddNewGood = () => {
    const { goods } = this.props;
    goods.currentGoodId = -1;
    this.setState({ formVisible: true });
  };

  //---------------------------------------------
  // закрыть форму
  //---------------------------------------------
  hideForm = () => {
    const { goods } = this.props;
    goods.currentGoodId = -1;
    this.setState({ formVisible: false });
    requestAnimationFrame(() => {
      this.refreshData();
    });
  };

  //---------------------------------------------
  // закрыть форму (при сохранение)
  //---------------------------------------------
  onSave = (closeFlag) => {
    const { goods } = this.props;
    goods.currentGoodId = -1;
    this.setState({ formVisible: !closeFlag });
    requestAnimationFrame(() => {
      this.refreshData();
    });
  };

  //---------------------------------------------
  // открыть форму
  //---------------------------------------------
  openForm = (id) => {
    const { goods } = this.props;
    goods.currentGoodId = id;
    this.setState({ formVisible: true });
  };

  //------------------------------------------------
  // рисовать состояние загрузки
  //------------------------------------------------
  renderLoadingState = () => {
    return (
      <Pane display={'flex'} justifyContent={'center'} marginTop={120}>
        <Spinner animation="border" role="status" variant="info" />
      </Pane>
    );
  };

  //---------------------------------------------
  // рисовать таблицу
  //---------------------------------------------
  renderTable = () => {
    const { goods } = this.props;

    const className = classNames("asap-foodstation-table-row");

    const RowsRenderData = goods.data.map((element, index) => {
      const basePrice = R.pathOr("0.0", ["basePrice"], element);
      const goodBarCode = R.pathOr("-", ["barCode"], element);

      const goodName = R.pathOr("Не удалось прочитать", ["name"], element);

      const remains = R.pathOr("-", ["remains"], element);

      return (
        <Table.Row
          key={element.id}
          isSelectable
          onSelect={() => {
            this.openForm(element.id);
          }}
        >
          <Table.TextCell
            width={48}
            flex="none"
            textProps={{ textAlign: "center" }}
          >
            {index + 1}
          </Table.TextCell>
          <Table.TextCell textProps={{ size: 400 }}>{goodName}</Table.TextCell>
          <Table.TextCell isNumber textProps={{ textAlign: "center" }}>
            {basePrice}
          </Table.TextCell>
          <Table.Cell
            isNumber
            justifyContnet={"center"}
            style={{ justifyContent: "flex-start" }}
          >
            <BarcodeIcon color="#E4E7EB" marginRight={16} />
            <Text>{goodBarCode}</Text>
          </Table.Cell>
          <Table.TextCell isNumber textProps={{ textAlign: "center" }}>
            -
          </Table.TextCell>
        </Table.Row>
      );
    });

    return <Table.VirtualBody height={500}>{RowsRenderData}</Table.VirtualBody>
  };

  render() {
    const { formVisible, searchString, loadingState } = this.state;

    const searchStringEmpty = searchString?.length ? true : false;
    const searchIsPosible = (searchString || "").length > 1 ? true : false;

    return (
      <Container style={Styles.container}>
        <Navbar bg="light" variant="light">
          <Nav className="mr-auto">
            {/* <Button variant="success" onClick={this.onPressAddNewGood}>
              Добавить группу
    </Button> */}
            <Button
              style={{ marginLeft: 8 }}
              variant="success"
              onClick={this.onPressAddNewGood}
            >
              Добавить товар
            </Button>
          </Nav>
          <Form inline>
            <div style={Styles.searchBarContainer}>
              <FormControl
                style={Styles.searchBarControl}
                type="text"
                placeholder="Наименование, штрих-код"
                className="mr-sm-2"
                value={searchString}
                onChange={(event) => this.onChangeInput(event.target.value)}
              />
              {searchStringEmpty && (
                <FaTimesCircle
                  style={Styles.clearIcon}
                  onClick={() => this.clearSearchString()}
                />
              )}
            </div>
            <Button
              disabled={!searchIsPosible}
              variant="outline-info"
              onClick={() => this.searchData()}
            >
              Найти
            </Button>
          </Form>
        </Navbar>
        <div className={"asap-table-foodstation-remains"}>
          <Table>
            <Table.Head>
              <Table.TextHeaderCell
                width={48}
                flex="none"
                textProps={{ textAlign: "center", fontSize: 14 }}
              >
                #
              </Table.TextHeaderCell>
              <Table.TextHeaderCell
                textProps={{ textAlign: "center", fontSize: 14 }}
              >
                Наименование
              </Table.TextHeaderCell>
              <Table.TextHeaderCell
                textProps={{ textAlign: "center", fontSize: 14 }}
              >
                Цена (руб.)
              </Table.TextHeaderCell>
              <Table.TextHeaderCell
                textProps={{ textAlign: "center", fontSize: 14 }}
              >
                Штрих-код
              </Table.TextHeaderCell>
              <Table.TextHeaderCell
                textProps={{ textAlign: "center", fontSize: 14 }}
              >
                Остаток (общий)
              </Table.TextHeaderCell>
            </Table.Head>
            {loadingState ? this.renderLoadingState() : this.renderTable()}
          </Table>
        </div>
        <GoodForm
          show={formVisible}
          onHide={() => this.hideForm()}
          onSave={(closeFlag) => this.onSave(closeFlag)}
        />
      </Container >
    );
  }
}

export default GoodsScreen;
