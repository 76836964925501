import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import appState from '../store/store';

import LoginScreen from '../screens/Login';
import MainScreen from '../screens/Main';


function UPFunction(){
  appState.superBIGDataAnalys();
}


//-----------------------------------------------------
// защищенный роут, необходимо войти если нету авторизации
//-----------------------------------------------------
function PrivateRoute({...rest}) {
  if (appState.sessionParams.isAuthenticated) {
    return <Route {...rest} />;
  }
  return (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  );
}

function AppNavigator() {
  return (
    <Switch>
      <Route path="/login" component={LoginScreen} />
      <PrivateRoute path="/" component={MainScreen} />
    </Switch>
  );
}

export default AppNavigator;
