import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "mobx-react";
import mainStore from "./store/store";
import goodsStore from './store/goods';
import stationRemains from './store/stationremains';
import foodStations from './store/stations';

import AppNavigator from "./navigation";

const stores = {
  mainStore,
  uiState : mainStore.uiState,    
  sessionParams: mainStore.sessionParams,
  goods: goodsStore,
  stationRemains,
  foodStations,
};

function App() {
  return (
    <Provider {...stores}>
      <BrowserRouter>
        <AppNavigator />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
