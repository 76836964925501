import axios from "axios";
import { buildApiRequest } from "./transport";
import { getMessage } from "./utils";

function requestList() {
  try {
    return buildApiRequest({
      method: "GET",
      target: "/good/list",
    });
  } catch (error) {
    console.log("ERROR", getMessage(error));
  }
}

function addNew(goodData) {
  const requestData = {
    name: goodData.name,
    basePrice: goodData.price,
    barCode: goodData.barCode,
    categoryId: goodData.categoryId,
    imageUrl: goodData.imageUrl,
  };
  try {
    return buildApiRequest({
      method: "POST",
      target: "/good/new",
      data: requestData,
    });
  } catch (error) {
    console.log("ERROR", getMessage(error));
  }
}

function update(goodData) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await buildApiRequest({
        method: "POST",
        target: "/good/update",
        data: {
          id: goodData.id,
          name: goodData.name,
          barCode: goodData.barCode,
          categoryId: goodData.categoryId || -1,
          basePrice: goodData.price,
          imageUrl: goodData.imageUrl,
        },
      });
      resolve(res);
    } catch (error) {
      console.log("API ERROR -> ", getMessage(error));
      reject(error);
    }
  });
}

function search(searchString) {
  try {
    return buildApiRequest({
      method: "GET",
      target: "/good/search",
      params: {
        search: searchString || "",
      },
    });
  } catch (error) {
    console.log("ERROR", getMessage(error));
  }
}

function requestById(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await buildApiRequest({
        method: "GET",
        target: "/good/get",
        params: {
          id: id,
        },
      });
      resolve(res);
    } catch (error) {
      console.log("API ERROR -> ", getMessage(error));
      reject(error);
    }
  });
}

function requestByBarCode(barCode) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await buildApiRequest({
        method: "GET",
        target: "/good/findByBarCode",
        params: {
          barCode,
        },
      });
      resolve(res);
    } catch (error) {
      console.log("API ERROR -> ", getMessage(error));
      reject(error);
    }
  });
}

function requestByIds(ids) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await buildApiRequest({
        method: "POST",
        target: "/good/findByIds",
        data: { ids },
      });
      resolve(res);
    } catch (error) {
      console.log("API ERROR -> ", getMessage(error));
      reject(error);
    }
  });
}

function uploadImage(fileData) {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append('UPLOADCARE_PUB_KEY', 'd910dc1048f9ea859f2c');
      formData.append('UPLOADCARE_STORE', '1');
      formData.append('file', fileData);
      const responseData = await fetch("https://upload.uploadcare.com/base/", {
        method: "POST",
        body: formData,
      });
      const responseImage = await responseData.json();
      console.log('image', responseImage);
      resolve({ success: true, imageUrl: 'https://ucarecdn.com/' +  responseImage.file + '/'});
    } catch (error) {
      console.log("API ERROR -> ", getMessage(error));
      reject(error);
    }
  });
}

export default {
  requestList,
  addNew,
  requestById,
  requestByIds,
  requestByBarCode,
  uploadImage,
  update,
  search,
};
