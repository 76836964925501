import user from './user';
import stations from './stations';
import goods from './goods';

const Application = {
    user,
    stations,
    goods,
}

export default Application;