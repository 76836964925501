import React, { Component } from "react";
import {
  Container,
  Navbar,
  Nav,
  FormControl,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";

import { Table, Text, MapIcon, MapMarkerIcon, Pane, IconButton, TrashIcon, MoreIcon } from "evergreen-ui";

import { FaTimesCircle } from "react-icons/fa";
import { inject, observer } from "mobx-react";
import classNames from "classnames";

import FoodStationForm from "./Form";

import Styles from "./styles";
import Application from "../../modules/application";

class FoodStationsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foodStationFormVisible: false,
      searchString: "",
      loadingState: true,
    };
  }

  async componentDidMount() {
    await this.refreshData();
  }

  //------------------------------------------------
  // при изменнении ввода
  //------------------------------------------------
  onChangeInput = (text) => {
    this.setState({ searchString: text });
  };

  //------------------------------------------------
  // очистить поиск
  //------------------------------------------------
  clearSearchString = () => {
    this.setState({ searchString: "" });
    this.refreshData();
  };

  //---------------------------------------------
  // обновить данные
  //---------------------------------------------
  refreshData = async () => {
    const { foodStations } = this.props;
    try {
      this.setState({ loadingState: true });
      const result = await Application.stations.requestList();
      this.setState({ loadingState: false });
      if (result.success) {
        foodStations.data = result.data;
      }
    } catch (error) {
      this.setState({ loadingState: false });
      console.log("ERROR", error);
    }
  };

  //---------------------------------------------
  //  найти
  //---------------------------------------------
  searchData = async () => {
    const { searchString } = this.state;
    const { foodStations } = this.props;
    try {
      this.setState({ loadingState: true });
      const result = await Application.stations.search(searchString);
      console.log("DATA", result);
      this.setState({ loadingState: false });
      if (result.success) {
        foodStations.data = result.data;
      }
    } catch (error) {
      this.setState({ loadingState: false });
      console.log("ERROR", error);
    }
  };

  //---------------------------------------------
  // добавить станцию
  //---------------------------------------------
  onPressAddNewFoodStation = () => {
    const { foodStations } = this.props;
    foodStations.currentRowId = -1;
    this.setState({ foodStationFormVisible: true });
  };

  //---------------------------------------------
  // закрыть форму
  //---------------------------------------------
  hideFoodStationForm = () => {
    const { foodStations } = this.props;
    foodStations.currentRowId = -1;
    this.setState({ foodStationFormVisible: false });
  };

  //---------------------------------------------
  // закрыть форму (при сохранение)
  //---------------------------------------------
  onSaveFoodStation = () => {
    const { foodStations } = this.props;
    this.setState({ foodStationFormVisible: false });
    foodStations.currentRowId = -1;
    requestAnimationFrame(() => {
      this.refreshData();
    });
  };

  //---------------------------------------------
  // открыть форму станции
  //---------------------------------------------
  openFoodStationForm = (id) => {
    const { foodStations } = this.props;
    foodStations.currentRowId = id;
    this.setState({ foodStationFormVisible: true });
  };

  //------------------------------------------------
  // рисовать состояние загрузки
  //------------------------------------------------
  renderLoadingState = () => {
    return (
      <Pane display={"flex"} justifyContent={"center"} marginTop={120}>
        <Spinner animation="border" role="status" variant="info" />
      </Pane>
    );
  };

  //---------------------------------------------
  // рисовать таблицу
  //---------------------------------------------
  renderTable = () => {
    const { foodStations } = this.props;

    const className = classNames("asap-foodstation-table-row");

    const RowsRenderData = foodStations.data.map((element, index) => {
      return (
        <Table.Row
          key={element.id}
          isSelectable
          onSelect={() => {
            this.openFoodStationForm(element.id);
          }}
        >
          <Table.TextCell
            width={48}
            flex="none"
            textProps={{ textAlign: "center" }}
          >
            {index + 1}
          </Table.TextCell>
          <Table.TextCell textProps={{ size: 400 }}>
            {element.title}
          </Table.TextCell>
          <Table.Cell
            isNumber
            textProps={{ textAlign: "center", fontSize: 14 }}
          >
            <MapMarkerIcon color="#EC4C47" marginRight={16} />
            <Text>{element.address}</Text>
          </Table.Cell>
         
          <Table.Cell
            isNumber
            justifyContnet={"center"}
            style={{ justifyContent: "flex-start" }}
          >
            <MapIcon color="#FAE2E2" marginRight={16} />
            <div>
              <Text color={"#8d8d8d"}>lat: </Text>
              <Text>{element.latitude}</Text>
              <br />
              <Text color={"#8d8d8d"}>lon: </Text>
              <Text>{element.longitude}</Text>
            </div>
          </Table.Cell>
         
          <Table.TextCell isNumber textProps={{ textAlign: "center" }}>
            {element.qrCode}
          </Table.TextCell>
          
        </Table.Row>
      );
    });

    return <Table.Body>{RowsRenderData}</Table.Body>;
  };

  render() {
    const { foodStationFormVisible, searchString, loadingState } = this.state;

    const searchStringEmpty = searchString?.length ? true : false;
    const searchIsPosible = (searchString || "").length > 1 ? true : false;

    return (
      <Container style={Styles.container}>
        <Navbar
          bg="light"
          variant="light"
          onSelect={this.onPressAddNewFoodStation}
        >
          <Nav className="mr-auto">
            <Button variant="success" onClick={this.onPressAddNewFoodStation}>
              Добавить станцию
            </Button>
          </Nav>
          <Form inline>
            <div style={Styles.searchBarContainer}>
              <FormControl
                style={Styles.searchBarControl}
                type="text"
                placeholder="Наименование, адрес"
                className="mr-sm-2"
                value={searchString}
                onChange={(event) => this.onChangeInput(event.target.value)}
              />
              {searchStringEmpty && (
                <FaTimesCircle
                  style={Styles.clearIcon}
                  onClick={() => this.clearSearchString()}
                />
              )}
            </div>
            <Button
              disabled={!searchIsPosible}
              variant="outline-info"
              onClick={() => this.searchData()}
            >
              Найти
            </Button>
          </Form>
        </Navbar>
        <Table responsive>
          <Table.Head>
            <Table.TextHeaderCell
              width={48}
              flex="none"
              textProps={{ textAlign: "center", fontSize: 14 }}
            >
              #
            </Table.TextHeaderCell>
            <Table.TextHeaderCell
              textProps={{ textAlign: "center", fontSize: 14 }}
            >
              Наименование
            </Table.TextHeaderCell>
            <Table.TextHeaderCell
              textProps={{ textAlign: "center", fontSize: 14 }}
            >
              Адрес
            </Table.TextHeaderCell>
            <Table.TextHeaderCell
              textProps={{ textAlign: "center", fontSize: 14 }}
            >
              Координаты (ширина, долгота)
            </Table.TextHeaderCell>
            <Table.TextHeaderCell
              textProps={{ textAlign: "center", fontSize: 14 }}
            >
              QR код
            </Table.TextHeaderCell>
          </Table.Head>
          {loadingState ? this.renderLoadingState() : this.renderTable()}
        </Table>
        <FoodStationForm
          show={foodStationFormVisible}
          onHide={() => this.hideFoodStationForm()}
          onSave={() => this.onSaveFoodStation()}
        />
      </Container>
    );
  }
}

const reduxConnector = inject("foodStations")(observer(FoodStationsScreen));

export default reduxConnector;
