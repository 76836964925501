export default {
  container: {
    marginTop: 64,
  },
  modalHeaderContainer: {
    borderBottom: 0,
  },
  emptyFormContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    height: 300,
  },
  processingText: {
    marginLeft: 16,
    fontSize: 24,
  },
  searchBarContainer: {
    position: "relative" as "relative",
    display: "flex",
    alignItems: "center",
  },
  clearIcon: {
    position: "absolute" as "absolute",
    right: 20,
    color: "#17a2b8",
  },
  searchBarControl:{
    paddingRight: 36,
  },
  qrCodeCol:{
    maxWidth: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
 //   whiteSpace: 'nowrap',
  },
  tableRowContainer:{
    "&:hover": {
      background: "#efefef"
    },
  }
};
