import _ from "lodash";
import CryptoJS from "crypto-js";

export function getMessage(obj: any, d: string = "Unknown"): any {
  if (_.isString(obj)) {
    return obj;
  }
  if (_.isObject(obj) && "message" in obj) {
    return obj.message;
  }
  return d;
}

export function generateRandomQR() {
  const salt = CryptoJS.lib.WordArray.random(128 / 8);
  
  const key256Bits = CryptoJS.PBKDF2("ASAP", salt, {
    keySize: 256 / 32,
  });
  
  return key256Bits.toString();
}
