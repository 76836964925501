import React, { Component } from "react";
import {
  Modal,
  Button,
  FormControl,
  Form,
  InputGroup,
  Container,
  Spinner,
} from "react-bootstrap";

import { Pane, Image, Text } from "evergreen-ui";
import { inject, observer } from "mobx-react";
import * as R from "ramda";

import ErrorImage from "../../../images/error.png";

import Application from "../../../modules/application";

import Styles from "../styles";

const FORM_STATE = {
  NEW: 0,
  OBJECT: 1,
  LOADING: 2,
  LOADING_ERROR: 3,
};

interface IProps {
  foodStationData?: any;
  onHide?: any;
  onSave?: any;
  show?: boolean;
  isNew?: boolean;
  dataId?: number;
  goods?: any;
}

interface IState {
  formInputs: any;
  formErrors: any;
  formState: number;
  imageUrl: string;
}

@inject("goods")
@observer
class GoodFormScreen extends Component<IProps, IState> {
  inputFileRef: any = null;
  imageFileData: any = null;

  fieldsValidationConfig = {
    name: {
      required: true,
    },
    barCode: {
      required: true,
    },
    price: {
      required: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      formInputs: {},
      formErrors: {},
      formState: FORM_STATE.LOADING,
      imageUrl: "",
    };
  }

  componentDidUpdate(prevProps) {
    const { show, goods } = this.props;
    // сбросить данные
    if (show !== prevProps.show) {
      if (show && goods.currentGoodId > 0) {
        this.setState({
          formInputs: {},
          formErrors: {},
          formState: FORM_STATE.LOADING,
          imageUrl: "",
        });
        this.imageFileData = null;
        this.requestObjectData();
      } else {
        this.setState({
          formInputs: {},
          formErrors: {},
          formState: FORM_STATE.NEW,
          imageUrl: "",
        });
        this.imageFileData = null;
      }
    }
  }

  //--------------------------------------------
  // добавить картинку
  //--------------------------------------------
  addImage = () => {
    if (this.inputFileRef) this.inputFileRef.click();
  };

  handleChange = async (e) => {
    if (e.target.files.length) {
      const fileType = R.pathOr("unknown", ["type"], e.target.files[0]);

      if (fileType !== "image/jpeg") {
        return alert("Поодерживаются только jpeg файлы изображений");
      }
      // запомнить
      this.imageFileData = e.target.files[0];
      this.setState({ imageUrl: URL.createObjectURL(this.imageFileData) });
      /* try {
       
        // загрузить
        const responseData: any = await Application.goods.uploadImage(e.target.files[0]);

        const result = R.contains(R.prop('success', responseData), [
          true,
        ]);

        if(result){
          this.setState({ imageUrl: responseData.imageUrl });
        }
        
        // const imageURL = URL.createObjectURL(e.target.files[0]);
        
      } catch (error) {
        console.log("ERROR", error);
      }*/
    }
  };

  //--------------------------------------------
  // получить данные
  //--------------------------------------------
  requestObjectData = async () => {
    const { goods } = this.props;
    try {
      const response: any = await Application.goods.requestById(
        goods.currentGoodId
      );
      const result = R.contains(R.prop("success", response), [true]);
      if (result) {
        this.setState({
          formInputs: { ...response.data, price: response.data.basePrice },
          formState: FORM_STATE.OBJECT,
          imageUrl: response.data.imageUrl,
        });
      }
    } catch (error) {
      this.setState({ formState: FORM_STATE.LOADING_ERROR });
    }
  };

  //--------------------------------------------
  // валидация данных
  //--------------------------------------------
  validateInputs = () => {
    const { formInputs } = this.state;

    const keys = Object.keys(this.fieldsValidationConfig);
    const errors = {};
    let hasErrors = false;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const valueValid = formInputs[key];
      errors[key] = !valueValid;
      if (!valueValid) hasErrors = true;
    }
    this.setState({ formErrors: errors });

    return !hasErrors;
  };

  //--------------------------------------------
  // добавить станцию еды на сервер
  //--------------------------------------------
  addOnServer = async () => {
    const { formInputs } = this.state;
    const { goods } = this.props;

    return new Promise(async (resolve, reject) => {
      if (this.validateInputs()) {
        this.setState({ formState: FORM_STATE.LOADING });
        try {
          let serverImageURL = "";
          // загрузить
          if (this.imageFileData) {
            const responseData: any = await Application.goods.uploadImage(
              this.imageFileData
            );

            const result = R.contains(R.prop("success", responseData), [true]);

            if (result) {
              serverImageURL = responseData.imageUrl;
            }
          }

          const response = await Application.goods.addNew({
            ...formInputs,
            imageUrl: serverImageURL,
          });
          const result = R.contains(R.prop("success", response), [true]);
          if (result) {
            goods.currentGoodId = response.data.id;
          }
          this.setState({ formState: FORM_STATE.OBJECT }, () => {
            return resolve();
          });
        } catch (error) {
          this.setState({ formState: FORM_STATE.LOADING_ERROR }, () => {
            return reject();
          });
        }
      }
    });
  };

  //--------------------------------------------
  // обновить данные на сервере сервер
  //--------------------------------------------
  updateOnServer = () => {
    const { formInputs, imageUrl } = this.state;
    const { goods } = this.props;

    return new Promise(async (resolve, reject) => {
      if (this.validateInputs()) {
        this.setState({ formState: FORM_STATE.LOADING });
        try {
          let serverImageURL = imageUrl;
          // загрузить
          if (this.imageFileData) {
            const responseData: any = await Application.goods.uploadImage(
              this.imageFileData
            );

            const result = R.contains(R.prop("success", responseData), [true]);

            if (result) {
              serverImageURL = responseData.imageUrl;
            }
          }
          await Application.goods.update({
            id: goods.currentGoodId,
            ...formInputs,
            imageUrl: serverImageURL,
          });
          this.setState({ formState: FORM_STATE.OBJECT }, () => {
            return resolve();
          });
        } catch (error) {
          this.setState({ formState: FORM_STATE.LOADING_ERROR });
          return reject();
        }
      }
    });
  };

  //------------------------------------------------
  // подтвердить (отправить на сервере) введенные данные в форме
  //------------------------------------------------
  postObject = (close = false) => {
    const { formState } = this.state;
    const { onSave } = this.props;

    if (formState === FORM_STATE.NEW) {
      return this.addOnServer().then(() => {
        if (onSave) {
          if (close) this.setState({ formInputs: {}, formErrors: {} });
          onSave(close);
        }
      });
    }

    return this.updateOnServer().then(() => {
      if (onSave) {
        if (close) this.setState({ formInputs: {}, formErrors: {} });
        onSave(close);
      }
    });
  };

  //------------------------------------------------
  // при изменнении ввода
  //------------------------------------------------
  onChangeInput = (type: string, text: string) => {
    this.setState(({ formInputs }) => ({
      formInputs: {
        ...formInputs,
        [type]: text,
      },
    }));
  };

  //-------------------------------------------------
  //
  //-------------------------------------------------
  renderErrorState = () => {
    return (
      <Container>
        <Modal.Header style={Styles.modalHeaderContainer} closeButton />
        <Container style={Styles.emptyFormContainer}>
          <Image src={ErrorImage} style={{ width: 64, height: 64 }} />
          <span style={Styles.processingText}>Ошибка чтения данных</span>
        </Container>
      </Container>
    );
  };

  //-------------------------------------------------
  //
  //-------------------------------------------------
  renderIndicator = () => {
    return (
      <Container>
        <Modal.Header style={Styles.modalHeaderContainer} closeButton />
        <Container style={Styles.emptyFormContainer}>
          <Spinner animation="border" role="status" variant="info" />
          <span style={Styles.processingText}>Обработка данных...</span>
        </Container>
      </Container>
    );
  };

  //-------------------------------------------------
  // рисовать форму
  //-------------------------------------------------
  renderFormData = (isNew = true) => {
    const { formInputs, formErrors, imageUrl } = this.state;

    // создать заголовок
    const title = isNew ? "Товар (Новый)" : "Товар";

    // изображение загружено
    const imageLoaded = R.pathOr(false, ["imageUrl"], this.state);

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Pane
            htmlFor="uploadButton"
            height={240}
            width={240}
            display="flex"
            alignItems="center"
            justifyContent="center"
            border="none"
            background="tint2"
            borderRadius={12}
            marginBottom={16}
            elevation={1}
            cursor="pointer"
            onClick={() => this.addImage()}
          >
            {!imageLoaded && <Text>{"Добавь фото товара"}</Text>}
            <input
              ref={(ref) => {
                this.inputFileRef = ref;
              }}
              type="file"
              style={{ display: "none" }}
              accept={""}
              onChange={(event) => this.handleChange(event)}
            />
            <Image src={imageUrl} />
          </Pane>
          {/*<Form.Group>
            <Form.Label>Группа</Form.Label>
            <FormControl
              type="text"
              placeholder={"Введите код или наименование группы"}
              className="mr-sm-2"
              isInvalid={formErrors.category || false}
              value={formInputs.category}
              onChange={(event) =>
                this.onChangeInput("category", event.target.value)
              }
            />
            </Form.Group>*/}
          <Form.Group>
            <Form.Label>Наименование</Form.Label>
            <FormControl
              type="text"
              className="mr-sm-2"
              isInvalid={formErrors.name || false}
              value={formInputs.name}
              onChange={(event) =>
                this.onChangeInput("name", event.target.value)
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Цена</Form.Label>
            <FormControl
              type="number"
              className="mr-sm-2"
              step="0.1"
              pattern="2-[0-9]{3}-[0-9]{3}"
              isInvalid={formErrors.price || false}
              value={formInputs.price}
              onChange={(event) =>
                this.onChangeInput("price", event.target.value)
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Штрих-код</Form.Label>
            <InputGroup>
              <FormControl
                type="text"
                className="mr-sm-2"
                isInvalid={formErrors.barCode || false}
                value={formInputs.barCode}
                onChange={(event) =>
                  this.onChangeInput("barCode", event.target.value)
                }
              />
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            color="primary"
            onClick={() => this.postObject(false)}
          >
            {isNew ? "Добавить" : "Сохранить"}
          </Button>
          <Button onClick={() => this.postObject(true)}>
            {isNew ? "Добавить и закрыть" : "Сохранить и закрыть"}
          </Button>
        </Modal.Footer>
      </>
    );
  };

  //------------------------------------------------------------
  // рисовать форму
  //------------------------------------------------------------
  renderForm = () => {
    const { formState } = this.state;

    switch (formState) {
      case FORM_STATE.NEW:
        return this.renderFormData(true);
      case FORM_STATE.OBJECT:
        return this.renderFormData(false);
      case FORM_STATE.LOADING:
        return this.renderIndicator();
      case FORM_STATE.LOADING_ERROR:
        return this.renderErrorState();
      default:
        return this.renderErrorState();
    }
  };

  render() {
    const { onHide, show } = this.props;

    return (
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={onHide && onHide}
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

export default GoodFormScreen;
