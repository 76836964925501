import { observable } from "mobx";

class FoodStationsState {
  @observable m_foodStations = [];

  @observable m_currentRowIdInTableList = -1;

  set data(data: any) {
    this.m_foodStations = data;
  }

  get data() {
    return this.m_foodStations;
  }

  set currentRowId(id: any) {
    this.m_currentRowIdInTableList = id;
  }

  get currentRowId() {
    return this.m_currentRowIdInTableList;
  }
}

export default new FoodStationsState();
