import { observable, computed } from 'mobx';

class UIState {
    @observable mainScreen = {
        currentMainSection: 'FoodStation',
    }

    @computed 
    set mainSection(section: string){
        this.mainScreen.currentMainSection = section;
    }
    
    get mainSection(){
       return this.mainScreen.currentMainSection;
    }
}

export default UIState;