import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import FoodStationsScreen from "../FoodStations";
import GoodsScreen from "../Goods";

import Styles from "./styles";

interface IProps {
  mainStore: any;
}

@inject("mainStore")
@observer
class Main extends Component<IProps> {
  //-------------------------------------------
  // изменить главную навигацию
  //-------------------------------------------
  onSelectSection = (nav) => {
    const { mainStore } = this.props;
    console.log("nav", nav);
    mainStore.uiState.mainSection = nav;
  };

  //--------------------------------------------
  // рисовать раздел
  //--------------------------------------------
  renderSection = () => {
    const { mainStore } = this.props;

    switch (mainStore.uiState.mainSection) {
      case "FoodStation":
        return <FoodStationsScreen />;
      case "Goods":
        return <GoodsScreen />;
      default:
        return <div />;
    }
  };

  render() {
    const { mainStore } = this.props;

    const mainSection = mainStore.uiState.mainSection;

    const selectedLinkStyle = { color: "#17a2b8" };

    return (
      <>
        <Navbar
          style={Styles.navbarContainer}
          variant="dark"
          onSelect={this.onSelectSection}
        >
          <Navbar.Brand>ASAP</Navbar.Brand>
          <Nav className="mr-auto">
            <Nav.Link
              eventKey="FoodStation"
              active={mainSection === "FoodStation"}
              style={mainSection === "FoodStation" ? selectedLinkStyle : {}}
            >
              Станции
            </Nav.Link>
            <Nav.Link
              eventKey="Goods"
              active={mainSection === "Goods"}
              style={mainSection === "Goods" ? selectedLinkStyle : {}}
            >
              Товары
            </Nav.Link>
          </Nav>
        </Navbar>
        {this.renderSection()}
      </>
    );
  }
}

export default Main;
