import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Form, Col, Spinner } from "react-bootstrap";
import { Pane, Image, Text, Heading } from "evergreen-ui";
import { FiX, FiCheck } from "react-icons/fi";
import * as R from "ramda";
import Application from "../../../modules/application";
import { getMessage } from "../../../modules/utils";

import Styles from "../styles";

const FORM_STATE = {
  NEW: 0,
  OBJECT: 1,
  LOADING: 2,
  LOADING_ERROR: 3,
};

class NewRemainsForm extends Component {
  inputFileRef;
  imageFileData = null;

  fieldsValidationConfig = {
    name: {
      required: true,
    },
    quantity: {
      required: true,
    },
    price: {
      required: true,
    },
    barCode: {
      required: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      formInputs: {},
      formErrors: {},
      processing: false,
      formState: FORM_STATE.NEW,
      imageUrl: "",
    };
  }

  componentDidMount(prevProps) {
    const { stationRemains, foodStations, show } = this.props;

    console.log("STATION REMAINS", stationRemains);
    // сбросить данные

    if (show && stationRemains.currentGoodId > 0) {
      this.setState({
        formInputs: {},
        formErrors: {},
        formState: FORM_STATE.LOADING,
        imageUrl: "",
      });
      this.imageFileData = null;
      this.requestObjectData();
    } else {
      this.setState({
        formInputs: {},
        formErrors: {},
        formState: FORM_STATE.NEW,
        imageUrl: "",
      });
      this.imageFileData = null;
    }
  }

  //--------------------------------------------
  // получить данные
  //--------------------------------------------
  requestObjectData = async () => {
    const { stationRemains, foodStations } = this.props;
    try {
      const response = await Application.stations.requestRemains(
        foodStations.currentRowId,
        stationRemains.currentGoodId
      );
      const result = R.contains(R.prop("success", response), [true]);

      // получить данные
      const goodData = R.pathOr({}, ["data", 0], response);

      // имя товара
      const goodName = R.pathOr("", ["good", "name"], goodData);
      // штрих код
      const goodBarCode = R.pathOr(0, ["good", "barCode"], goodData);
      // ссылка на изображение
      const goodImageUrl = R.pathOr("", ["good", "imageUrl"], goodData);
      // остаток
      const goodQuantity = R.pathOr(0, ["quantity"], goodData);
      // цена
      const goodPrice = R.pathOr(0, ["price"], goodData);

      console.log("IMAGE", goodQuantity);
      if (result) {
        this.setState({
          formInputs: {
            price: goodPrice,
            quantity: goodQuantity,
            name: goodName,
            barCode: goodBarCode,
          },
          formState: FORM_STATE.OBJECT,
          imageUrl: goodImageUrl,
        });
      }
    } catch (error) {
      this.setState({ formState: FORM_STATE.LOADING_ERROR });
    }
  };

  //--------------------------------------------
  // добавить картинку
  //--------------------------------------------
  addImage = () => {
    if (this.inputFileRef) this.inputFileRef.click();
  };

  handleChange = async (e) => {
    if (e.target.files.length) {
      const fileType = R.pathOr("unknown", ["type"], e.target.files[0]);

      if (fileType !== "image/jpeg") {
        return alert("Поодерживаются только jpeg файлы изображений");
      }
      // запомнить
      this.imageFileData = e.target.files[0];
      this.setState({ imageUrl: URL.createObjectURL(this.imageFileData) });
    }
  };

  //--------------------------------------------
  // валидация данных
  //--------------------------------------------
  validateInputs = () => {
    const { formInputs } = this.state;

    const keys = Object.keys(this.fieldsValidationConfig);
    const errors = {};
    let hasErrors = false;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const valueValid = formInputs[key];
      errors[key] = !valueValid;
      if (!valueValid) hasErrors = true;
    }
    this.setState({ formErrors: errors });

    return !hasErrors;
  };

  //------------------------------------------------
  // подтвердить (отправить на сервере) введенные данные в форме
  //------------------------------------------------
  postObject = async () => {
    const { formInputs, imageUrl } = this.state;
    const { foodStations, onConfirm } = this.props;

    if (this.validateInputs()) {
      try {
        this.setState({ processing: true });

        // инициализировать из состояния
        let serverImageURL = imageUrl;
        // загрузить (изображение на сервер)
        if (this.imageFileData) {
          const responseData = await Application.goods.uploadImage(
            this.imageFileData
          );

          const result = R.contains(R.prop("success", responseData), [true]);

          if (result) {
            serverImageURL = responseData.imageUrl;
          }
        }
        // НАТИЙ ТОВАР ПО ШТРИХ-КОДУ
        const goodResponse = await Application.goods.requestByBarCode(
          formInputs.barCode
        );
        const result = R.contains(R.prop("success", goodResponse), [true]);

        if (result) {
          // СОЗДАТЬ ТОВАР
          if (goodResponse.data.length === 0) {
            const goodResponse = await Application.goods.addNew({
              ...formInputs,
              imageUrl: serverImageURL,
            });

            const result = R.contains(R.prop("success", goodResponse), [true]);
            if (result) {
              // ОБНОВИТЬ ОСТАТКИ
              await Application.stations.updateRemains({
                stationId: foodStations.currentRowId,
                goodId: goodResponse.data.id,
                price: formInputs.price,
                quantity: formInputs.quantity,
              });

              // все успешно
              this.setState({ processing: false });
              if (onConfirm) {
                onConfirm();
              }
            }
          } else {
            console.log('GOOD DATA UPDATE', goodResponse);
            // ОБНОВИТЬ ТОВАР
            await Application.goods.update({
              id: goodResponse.data.id,
              ...formInputs,
              imageUrl: serverImageURL,
            });

            // ОБНОВИТЬ ОСТАТКИ
            await Application.stations.updateRemains({
              stationId: foodStations.currentRowId,
              goodId: goodResponse.data.id,
              price: formInputs.price,
              quantity: formInputs.quantity,
            });

            // все успешно
            this.setState({ processing: false });
            if (onConfirm) {
              onConfirm();
            }
          }
        }
        console.log("BARCODE", goodResponse);
      } catch (error) {
        console.log("Error", getMessage(error));
      }
    }
  };

  //------------------------------------------------
  // при изменнении ввода
  //------------------------------------------------
  onChangeInput = (type, text) => {
    this.setState(({ formInputs }) => ({
      formInputs: {
        ...formInputs,
        [type]: text,
      },
    }));
  };

  //------------------------------------------------
  // рисовать состояние загрузки
  //------------------------------------------------
  renderLoadingState = () => {
    return (
      <Pane>
        <Spinner marginX="auto" marginY={120} />
      </Pane>
    );
  };

  render() {
    const { processing, formErrors, formInputs, imageUrl, formState } = this.state;
    const { onCancel, show } = this.props;

    if (!show) return null;

    // изображение загружено
    const imageLoaded = R.pathOr(false, ["imageUrl"], this.state);

    return (
      <Form className={"asap-new-remains-form-container"}>
        <Pane display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Pane display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
            <Heading size={700} marginTop="12" marginBottom={12} marginRight={8}>
              Информация о товаре на станции
          </Heading>
            {formState === FORM_STATE.LOADING && <Spinner animation="border" role="status" variant="info" />}
          </Pane>
          <FiX
            className={"asap-cancel-icon-contaner"}
            onClick={() => onCancel && onCancel()}
          />
        </Pane>
        <Form.Row>
          <Pane
            htmlFor="uploadButton"
            height={96}
            width={96}
            display="flex"
            alignItems="center"
            justifyContent="center"
            border="solid"
            background="tint2"
            borderRadius={12}
            marginBottom={16}
            cursor="pointer"
            padding={5}
            onClick={() => this.addImage()}
          >
            {!imageLoaded && (
              <Text textAlign={"center"}>{"Добавь фото товара"}</Text>
            )}
            <input
              ref={(ref) => {
                this.inputFileRef = ref;
              }}
              type="file"
              style={{ display: "none" }}
              accept={""}
              onChange={(event) => this.handleChange(event)}
            />
            <Image src={imageUrl} style={{height: 90}}/>
          </Pane>
        </Form.Row>
        <Form.Row>
          <Col sm="5">
            <Form.Group controlId="formGridGood">
              <Form.Label>Наименование</Form.Label>
              <Form.Control
                className={"asap-new-remain-number-row"}
                type="text"
                isInvalid={formErrors.name || false}
                placeholder="Наименование товара"
                value={formInputs.name}
                onChange={(event) =>
                  this.onChangeInput("name", event.target.value)
                }
              />
            </Form.Group>
          </Col>

          <Col sm="2">
            <Form.Group controlId="formGridGood">
              <Form.Label>Штрих-код</Form.Label>
              <Form.Control
                className={"asap-new-remain-number-row"}
                type="text"
                isInvalid={formErrors.barCode || false}
                placeholder="Штрих-код"
                value={formInputs.barCode}
                onChange={(event) =>
                  this.onChangeInput("barCode", event.target.value)
                }
              />
            </Form.Group>
          </Col>

          <Col sm="2">
            <Form.Group controlId="formGridQuantityRemain">
              <Form.Label>Остаток</Form.Label>
              <Form.Control
                className={"asap-new-remain-number-row"}
                type="number"
                placeholder="0"
                isInvalid={formErrors.quantity || false}
                value={formInputs.quantity}
                onChange={(event) =>
                  this.onChangeInput("quantity", event.target.value)
                }
              />
            </Form.Group>
          </Col>
          <Col sm="2">
            <Form.Group controlId="formGridPrice">
              <Form.Label>Цена</Form.Label>
              <Form.Control
                className={"asap-new-remain-number-row"}
                type="number"
                placeholder="00.0"
                isInvalid={formErrors.price || false}
                value={formInputs.price}
                onChange={(event) =>
                  this.onChangeInput("price", event.target.value)
                }
              />
            </Form.Group>
          </Col>
          <Col sm="1">
            <Form.Label></Form.Label>
            <Form.Group
              controlId="formGridPrice"
              className={"asap-new-remains-action-block"}
            >
              {processing ? (
                <Spinner animation="border" role="status" variant="info" />
              ) : (
                  <FiCheck
                    className={"asap-confirm-icon-contaner"}
                    onClick={() => this.postObject()}
                  />
                )}
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

NewRemainsForm.defaultProps = {
  show: false,
};

const reduxConnector = inject(
  "stationRemains",
  "foodStations"
)(observer(NewRemainsForm));

export default reduxConnector;
