import { buildApiRequest } from "./transport";
import goods from "./goods";
import { getMessage } from "./utils";

function requestList() {
  try {
    return buildApiRequest({
      method: "GET",
      target: "/station/list",
    });
  } catch (error) {
    console.log("ERROR", getMessage(error));
  }
}

function addNew(stationData) {
  try {
    return buildApiRequest({
      method: "POST",
      target: "/station/new",
      data: {
        title: stationData.title,
        qrCode: stationData.qrCode,
        latitude: stationData.latitude || 0.0,
        longitude: stationData.longitude || 0.0,
        address: stationData.address,
      },
    });
  } catch (error) {
    console.log("ERROR", getMessage(error));
  }
}

function update(stationData) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await buildApiRequest({
        method: "POST",
        target: "/station/update",
        data: {
          id: stationData.id,
          title: stationData.title,
          qrCode: stationData.qrCode,
          latitude: stationData.latitude || 0.0,
          longitude: stationData.longitude || 0.0,
          address: stationData.address,
        },
      });
      resolve(res);
    } catch (error) {
      console.log("API ERROR -> ", getMessage(error));
      reject(error);
    }
  });
}

function search(searchString) {
  try {
    return buildApiRequest({
      method: "GET",
      target: "/station/search",
      params: {
        search: searchString || "",
      },
    });
  } catch (error) {
    console.log("ERROR", getMessage(error));
  }
}

function requestById(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await buildApiRequest({
        method: "GET",
        target: "/station/get",
        params: {
          id: id,
        },
      });
      resolve(res);
    } catch (error) {
      console.log("API ERROR -> ", getMessage(error));
      reject(error);
    }
  });
}

function requestRemains(stationId, goodId = null) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await buildApiRequest({
        method: "GET",
        target: "/stationremains/slice",
        params: {
          stationId,
          goodId,
        },
      });

      resolve(res);
    } catch (error) {
      console.log("API ERROR -> ", getMessage(error));
      reject(error);
    }
  });
}

function updateRemains(remainsData) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await buildApiRequest({
        method: "POST",
        target: "/stationremains/update",
        data: {
          stationId: remainsData.stationId,
          goodId: remainsData.goodId,
          price: remainsData.price,
          quantity: remainsData.quantity,
        },
      });
      resolve(res);
    } catch (error) {
      console.log("API ERROR -> ", getMessage(error));
      reject(error);
    }
  });
}

function deleteRemainsRecord(remainsData) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await buildApiRequest({
        method: "POST",
        target: "/stationremains/deleteRecord",
        data: {
          stationId: remainsData.stationId,
          goods: [
            {
              id: remainsData.goodId,
            },
          ],
        },
      });
      resolve(res);
    } catch (error) {
      console.log("API ERROR -> ", getMessage(error));
      reject(error);
    }
  });
}

export default {
  requestList,
  addNew,
  requestById,
  requestRemains,
  updateRemains,
  deleteRemainsRecord,
  update,
  search,
};
