import { observable } from "mobx";

class StationRemainsState {
  @observable m_stationRemains = [];

  @observable m_currentInTableRowId = -1;

  @observable m_currentGoodId = -1;

  set data(data: any) {
    this.m_stationRemains = data;
  }

  get data() {
    return this.m_stationRemains;
  }

  set currentRowId(id: any) {
    this.m_currentInTableRowId = id;
  }

  get currentRowId() {
    return this.m_currentInTableRowId;
  }

  set currentGoodId(id: any) {
    this.m_currentGoodId = id;
  }

  get currentGoodId() {
    return this.m_currentGoodId;
  }
}

export default new StationRemainsState();