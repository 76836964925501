import { observable, computed, action } from 'mobx';
import UIState from './uistate';

class SessionParams {
    @observable m_userAuthCode = '';

    @observable m_isAuthenticated = false;

    @computed
    set userAuthCode(code) {
      this.m_userAuthCode = code;
    }

    get userAuthCode() {
      return this.m_userAuthCode;
    }

    set isAuthenticated(flag) {
      this.m_isAuthenticated = flag;
    }

    get isAuthenticated() {
      return this.m_isAuthenticated;
    }
}

class ApplicationState {
    sessionParams: any;
    uiState: any;

    @observable m_foodStations = [];

    @observable m_goods = [];

    constructor() {
      this.sessionParams = new SessionParams();
      this.uiState = new UIState();
    }

    @computed
    set foodStations(data: any){
      this.m_foodStations = data;
    }

    get foodStations(){
      return this.m_foodStations;
    }

    set goods(data: any){
      this.m_goods = data;
    }

    get goods(){
      return this.m_goods;
    }

    @action
    superBIGDataAnalys(){
      // TODO CODE
    }
}

export default new ApplicationState();
