import axios, { AxiosTransformer } from 'axios';
import _ from 'lodash';
import moment from 'moment';

export const ENDPOINT_API = 'https://station.asapme.club/api'; ///`${process.env.REACT_APP_ENDPOINT_API}/api`;

type RequestMethod = "GET" | "POST" | "PATCH" | "DELETE";

type BuildOptions = {
  method: RequestMethod;
  target: string;
  params?: any;
  data?: any;
};

export const dateTimeRx = new RegExp(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/);

export function def(v: any, d: any = null): any {
	return _.isUndefined(v) || _.isNaN(v) ? d : v;
}

// Для оптимизации названий ключей
function optimizeKey(key: string, camelKey: string): string {
	switch (key) {
	case 'ID':
	case 'UID':
	case 'GUID':
	case 'EMail':
		return key.toLowerCase();
	case 'ConfirmedEMail':
		return 'confirmedEmail';
	case 'SMSTimer':
		return 'smsTimer';
	default:
		return camelKey;
	}
}

function isDateTimeKey(key: string): boolean {
	const lowerKey = key.toLowerCase();
	return lowerKey.includes('time') || lowerKey.includes('date') || lowerKey === 'start' || lowerKey === 'finish';
}

export function toObjectCamel(o: any, parentKey: string = '', parseDates: boolean = true): any {
	if (_.isArray(o)) {
		const res: any = [];
		for (const x in o) {
			// noinspection JSUnfilteredForInLoop
			res.push(toObjectCamel(o[x], parentKey, parseDates));
		}
		return res;
	}
	if (_.isObject(o)) {
		return _.transform(
			o,
			(result: any, value: any, key: string) => {
				const optimizedKey = optimizeKey(key, _.camelCase(key));
				result[optimizedKey] = toObjectCamel(value, optimizedKey, parseDates);
			},
			{},
		);
	}
	if (_.isString(o) && parseDates && parentKey && isDateTimeKey(parentKey) && (!dateTimeRx || dateTimeRx.test(o))) {
		return def(
			moment(o)
				.utc(true)
				.valueOf(),
			o,
		);
	}
	return o;
}


//----------------------------------------------------
// axios camel transformer
//----------------------------------------------------
export const axiosCamelTransformer: AxiosTransformer = (data: any, headers?: any): any => {
	try {
		return toObjectCamel(JSON.parse(data), '', true);// /camelize(data);
	} catch (e) {
		console.log('ERROR CAMELIZE', e);
		return data;
	}
};

let list: Array<any> = [];
// Use camel response data transformer
axios.defaults.transformResponse = list.concat(axiosCamelTransformer, axios.defaults.transformResponse);

// add token
axios.defaults.headers.common['authcode'] = 'demo';

//----------------------------------------------------
// axios interceptor
//----------------------------------------------------
axios.interceptors.request.use((request) => {
  console.log("Starting Request", request);
  return request;
});

//----------------------------------------------------
// axios interceptor
//----------------------------------------------------
axios.interceptors.response.use((response) => {
  console.log("Starting Response", response);
  return response;
});

export function buildApiRequest(options: BuildOptions): Promise<any> {

  let endpointURL = ENDPOINT_API;
  
  return new Promise((resolve, reject) => {
    axios({
      ...(options || {}),
      url: `${endpointURL}${options.target}`,
      // withCredentials: true
    })
      .then((response) =>
        // eslint-disable-next-line no-undef
        requestAnimationFrame(() => resolve(response.data))
      )
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        // eslint-disable-next-line no-undef
        requestAnimationFrame(() => reject(error));
      });
  });
}
